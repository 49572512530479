import { ListViewComponent } from './../../list-view/list-view.component';
import { ProductTypeEnum } from '../../../enums/product-type.enum';
import { IdentificationCustomer } from '../../../controls/customer-identification-control/customer-identification-control.component';
import { ListViewSelectionMode } from './../../../enums/list-view-selection-mode.enum';
import { Component, Input, OnInit, ViewChild, AfterViewInit} from "@angular/core";
import { PagedResult } from './../../../models/list-view/pagedResult';

@Component({
    selector: 't4-order-lines',
    templateUrl: './order-lines.component.html'
})
export class OrderLinesComponent implements OnInit, AfterViewInit {
    public ProductTypeEnum = ProductTypeEnum;

    @Input() public orderLines: Array<any>;
    @Input() public secondaryTicketingActive: boolean = false;
    @Input() public isIbaActive: boolean = false;
    @Input() public isSecundaryShop: boolean = false;

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERDETAILS.ORDERLINES.NOLINES',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        headers: [
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.PRODUCT', hideOn: null},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.SECTIONROWSEAT', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.ENTRANCE', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TICKETTEXT', hideOn: null, width: 200},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TYPE', hideOn: null, width: 150},
            {sortField: null, label: 'T4.ORDERDETAILS.HEADER.INTHENAMEOF', hideOn: null, width: 190}
        ]
      };

    public orderLinesLength;
    public pagedOrderLines;
    private checkedTicketStatus: boolean = false;
    public hasTicketStatus: boolean = false;

    constructor() { }

    doPage($event) {
        this.pagedOrderLines = this.orderLines.slice(($event.pageNr - 1) * $event.pageSize, ($event.pageNr) * $event.pageSize);

        let d : PagedResult = {
            TotalRecords: this.orderLinesLength,
            Records: this.pagedOrderLines
        }

        this.listVw.Data = d;
        if(!this.checkedTicketStatus){

            if (this.pagedOrderLines.filter((orderLine) => orderLine.TicketStatus != null).length > 0 && !this.isSecundaryShop) {
                this.listOptions.headers.push({sortField: null, label: 'T4.ORDERDETAILS.HEADER.SECONDARY_TICKETING', hideOn: null, width: 80})
                this.checkedTicketStatus = true;
                this.hasTicketStatus = true;
            }
            this.checkedTicketStatus = true;
            this.listOptions.headers.push(
                {sortField: null, label: 'T4.ORDERDETAILS.HEADER.CATEGORY', hideOn: null, width: 130},
                {sortField: null, label: 'T4.ORDERDETAILS.HEADER.EXVAT', hideOn: null, width: 80},
                {sortField: null, label: 'T4.ORDERDETAILS.HEADER.VAT', hideOn: null, width: 80},
                {sortField: null, label: 'T4.ORDERDETAILS.HEADER.TOTAL', hideOn: null, width: 80},
            );
            this.listVw.setOptions(this.listOptions)
            this.listVw.refreshData();
        }

    }

    ngOnInit(): void {
        this.orderLines = this.orderLines.filter(ol => ol.ProductType !== ProductTypeEnum.Discount);

        this.orderLinesLength = this.orderLines.length;
    }

    ngAfterViewInit() {}

    constructCustomerIdentificationModel(orderLine): IdentificationCustomer {
        return {
          CalculatedName: orderLine.TicketCustomerCalculatedName,
          CustomerType: orderLine.TicketCustomerType,
          CustomerName: orderLine.TicketCustomer,
          IsIdentified: orderLine.TicketCustomerIsIdentified,
          CustomerNumber: orderLine.TicketCustomerNumber,
          TicketCustomerId: orderLine.TicketCustomerId,
        }
      }

}
