<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{'DIALOG.MERGE_CUSTOMER_DIALOG.TITLE' | translate}}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>
  <form class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row">
          <div class="col-lg-12">
            <p>{{ 'DIALOG.MERGE_CUSTOMER_DIALOG.MSG' | translate }}</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <t4-customer-selector #customerSelector ></t4-customer-selector>
          </div>
        </div>
        <div class="form-layout-footer row">
          <div class="col-md-6">
            <button (click)="mergeCustomer()"  class="btn btn-block btn-primary bd-0">
              <i class="far fa-save"></i><span>{{'BTN.MERGE_CUSTOMER_DIALOG.MERGE' | translate}}</span>
            </button>
          </div>
          <div class="col-md-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{'BTN.CANCEL' | translate}}</span></button>
          </div>
        </div>
      </div>
    </div>  
  </form>
  